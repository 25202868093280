import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

// the purpose of this guard is to check for unsaved changes on the current page before allowing navigation away
@Injectable()
export class CanDeactivateComponentGuard  {
    canDeactivate(component: ICanDeactivateComponent): Observable<boolean> {
        return component.canDeactivate();
    }
}

export interface ICanDeactivateComponent {
    canDeactivate(): Observable<boolean>;
}
